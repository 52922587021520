<template>
  <v-expansion-panel>
    <v-expansion-panel-content
      v-for="section in visibleSections()"
      :key="section.name"
    >
      <template v-slot:header>
        {{ section.name }}
      </template>
      <v-card>
        <v-card-text
          v-if="section.description"
          class="ml-2 grey--text text--darken-2 subheading"
          >{{ section.description }}</v-card-text
        >
        <v-layout justify-space-between v-if="section.outputs.length > 10" class="mx-4">
          <v-flex xs12 offset-md6 md6 offset-xl7 xl5>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-data-table
          :headers="headers"
          :items="section.outputs"
          :search="search"
          disable-initial-sort
          :rows-per-page-items="[10, 25]"
          :hide-actions="section.outputs.length <= 10"
          :filter="filter"
          v-if="section.outputs.length > 0"
          class="mx-4 my-2"
        >
          <template v-slot:items="props">
            <td @click="openInNewTab(props.item.filename)" class="table-cell">
              {{ getFileDisplayName(props.item.filename) }}
              <span v-if="props.item.helptext" class="ml-2 mr-4"><PopOut icon="help">{{ props.item.helptext }}</PopOut></span>
            </td>
            <td @click="openInNewTab(props.item.filename)" class="table-cell">
              <v-layout align-center justify-end>
                <v-flex shrink>
                  <v-chip
                    color="green lighten-4"
                    class="ml-2"
                    v-if="getExtension(props.item.filename) === 'html'"
                  >
                    Interactive
                  </v-chip>
                </v-flex>
                <v-flex shrink>
                  <v-chip
                    color="orange lighten-4"
                    class="ml-2"
                    v-if="
                      getExtension(props.item.filename) !== 'html' &&
                      getExtension(props.item.filename) !== 'pdf'
                    "
                  >
                    Raw
                  </v-chip>
                </v-flex>
                <v-flex shrink>
                  <v-chip color="blue lighten-4" class="ml-2">
                    <v-avatar>
                      <v-icon size="22px">{{
                        getExtensionIcon(props.item.filename)
                      }}</v-icon>
                    </v-avatar>
                    {{ getExtension(props.item.filename).toUpperCase() }}
                  </v-chip>
                </v-flex>
                <v-flex v-for="perItemAction in (section.perItemActions || [])" :key="perItemAction.type">
                  <v-btn
                    v-if="perItemAction.type === PerItemActionType.BlastAssembly && props.item.size < MaxBLASTSize"
                    v-on:click.stop="actionBlast(props.item.filename)"
                    class="px-3"
                  >
                    BLAST
                    <v-icon class="ml-3">line_style</v-icon>
                  </v-btn>
                  <v-tooltip
                    v-if="perItemAction.type === PerItemActionType.BlastAssembly && props.item.size >= MaxBLASTSize"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn disabled class="px-3">
                          BLAST
                          <v-icon class="ml-3">line_style</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Assembly is too large to submit to BLAST from BugSeq. You can download the assembly FASTA and submit it manually to BLAST.</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </td>
          </template>
        </v-data-table>
        <ResultsTable
          v-if="section.subsections.length > 0"
          :free="free"
          :sections="section.subsections"
          :job="job"
          class="pl-4 nested-results-table"
        />
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Store } from "vuex";
import { components } from "@bugseq-site/app/src/lib/api/api";
import { dispatchGetFileLink } from "@bugseq-site/app/src/store/modules/api/actions";
import PopOut from "@bugseq-site/shared/src/components/PopOut.vue";

export enum PerItemActionType {
  BlastAssembly
}

interface PerItemAction {
  type: PerItemActionType;
}

export interface Section {
  name: string;
  description?: string;
  filterFunc: (f: components["schemas"]["JobRunFile"]) => boolean;
  outputs: components["schemas"]["JobRunFile"][];
  subsections: Section[];
  hidden: boolean;
  perItemActions?: PerItemAction[];
}

const ComponentProps = Vue.extend({
  props: {
    free: {
      type: Boolean,
      default: false,
    },
    sections: Array as PropType<Section[]>,
    job: String,
  },
});

const summaryHtmlFilter = (filename: string): boolean =>
  filename.startsWith("summary_report") && filename.endsWith(".html"); // covers summary_report- and summary_reports/
const summaryPdfFilter = (filename: string): boolean =>
  filename.startsWith("summary_report") && filename.endsWith(".pdf"); // covers summary_report- and summary_reports/

@Component({
  components: { PopOut },
  name: 'ResultsTable', // https://github.com/kaorun343/vue-property-decorator/issues/102
})
export default class ResultsTable extends ComponentProps {
  PerItemActionType: any = PerItemActionType; // https://stackoverflow.com/a/60920165

  // this limit was discovered empirically
  // by just elongating https://blast.ncbi.nlm.nih.gov/Blast.cgi?PROGRAM=blastn&PAGE_TYPE=BlastSearch&LINK_LOC=blasthome&DATABASE=nt&QUERY=
  // appending `a` to the end until blast served a 414.
  //
  // the limit seems to apply to the whole url.
  // so we eek out a few more chars by omitting LINK_LOC
  // https://blast.ncbi.nlm.nih.gov/Blast.cgi?PROGRAM=blastn&DATABASE=nt&PAGE_TYPE=BlastSearch&QUERY= (with 8105 'a' after)
  MaxBLASTSize: number = 8105

  public search: string = ""
  public headers = [
    {
      text: 'Filename',
      align: 'left',
      sortable: false,
      value: 'filename'
    },
    {
      text: '',
      align: 'right',
      sortable: false,
      width: '1%',
      value: ''
    },
  ]

  public async openInNewTab(filename) {
    const fileLink = await dispatchGetFileLink(this.$store, {
      jobId: this.job,
      filename,
      free: this.free,
    });
    const newWindow = window.open(fileLink, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  }

  public getFileDisplayName(filename) {
    const ext = this.getExtension(filename);
    return filename.substring(0, filename.length - ext.length - 1); // extra 1 for the .
  }

  public getExtensionIcon(filename) {
    const ext = this.getExtension(filename);
    switch (ext) {
      case "html":
        return "travel_explore";
      case "pdf":
        return "auto_graph";
      case "jpeg":
      case "jpg":
      case "png":
      case "gif":
        return "mdi-image-outline";
      case "txt":
        return "rtt";
      default:
        return "code";
    }
  }

  public getExtension(filename) {
    const split = filename.split(".");
    let ext = split.pop();
    if (ext === "gz") {
      ext = split.pop() + "." + ext;
    }
    return ext;
  }

  public visibleSections() {
    return this.sections.filter(
      (s) =>
        !s.hidden &&
        (s.outputs.length > 0 ||
          s.subsections.flatMap((o) => o.outputs).length > 0),
    );
  }

  public async actionBlast(filename: string) {
    const params = new URLSearchParams()
    params.set("PROGRAM", "blastn")
    params.set("DATABASE", "nt")
    params.set("PAGE_TYPE", "BlastSearch")

    const fileLink = await dispatchGetFileLink(this.$store, {
      jobId: this.job,
      filename,
      free: this.free,
    });
    const resp = await fetch(fileLink)
    const assemblyBlob = await resp.blob()
    const assemblyContents = await assemblyBlob.text()

    params.set("QUERY", assemblyContents)

    const newWindow = window.open(`https://blast.ncbi.nlm.nih.gov/Blast.cgi?${params.toString()}`, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  }

  public filter(val: string, search: string): boolean {
    if (!val) {
      return false
    }
    return val.toLowerCase().includes(search.toLowerCase())
  }
}
</script>

<style scoped>
.table-cell {
  cursor: pointer;
}

.nested-results-table {
  box-shadow: none;
}
</style>
