<template>
  <v-card class="ma-3 google-font full-size-font" v-if="loading()">
    <v-system-bar window>
      Results
    </v-system-bar>
    <v-progress-circular
      indeterminate
      :width="2"
      color="black"
      class="ma-3"
    ></v-progress-circular>
  </v-card>
  <v-card class="ma-3 google-font full-size-font" v-else>
    <v-system-bar window> Results<v-spacer />{{ job }} </v-system-bar>
    <v-list two-line class="pa-2">
      <v-list-tile v-if="results.user_provided_name">
        <v-list-tile-content>
          <v-list-tile-title class="full-size-font">Name</v-list-tile-title>
          <v-list-tile-sub-title class="full-size-font">{{
            results.user_provided_name
          }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-title class="full-size-font">
            Status
          </v-list-tile-title>
          <v-list-tile-sub-title class="full-size-font">
            <span v-if="incomplete()">
              Analysis in progress
            </span>
            <span v-else>
              Analysis complete
            </span>
            <JobResultsChip :jobStatus="results.job_status" />
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-title class="full-size-font"
            >Submitted</v-list-tile-title
          >
          <v-list-tile-sub-title class="full-size-font">{{
            results.created | formatTime
          }}<span v-if="results.org_id && getOwnerName() && getLabName()"> by {{ getOwnerName() }} under <a :href="`/app/main/labs/${getLabId()}`" target="_blank">{{ getLabName() }}</a></span></v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile v-if="results.end_time">
        <v-list-tile-content>
          <v-list-tile-title class="full-size-font"
            >Completed</v-list-tile-title
          >
          <v-list-tile-sub-title class="full-size-font">{{
            results.end_time | formatTime
          }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile class="input-files-list-tile">
        <v-list-tile-content>
          <v-list-tile-title class="full-size-font"
            >Input Files</v-list-tile-title
          >
          <v-list-tile-sub-title class="full-size-font input-files"
            >{{ getInputFiles() }}
            <v-btn
              v-if="shouldTruncateInputFiles()"
              class="text-capitalize"
              @click="() => (inputFilesTruncated = false)"
              >View All</v-btn
            ></v-list-tile-sub-title
          >
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
    <div class="px-3 pb-3 ml-2">
      <div v-if="incomplete()">
        This analysis is still running. If it has been more than 24 hours,
        please contact
        <a href="mailto:support@bugseq.com">support@bugseq.com</a> so we can
        look into it.
      </div>
      <div v-else-if="noResultsProduced()">
        No results found. Please contact
        <a href="mailto:support@bugseq.com">support@bugseq.com</a> so we can
        look into it.
      </div>
      <div v-else>
        <div
          class="mb-4"
          v-if="getSummaryHtml() || getSummaryPdf() || getFullZipFile()"
        >
          <div class="mb-2">
            Quick Links
          </div>
          <v-btn
            v-if="getSummaryHtml()"
            v-on:click="openInNewTab(getSummaryHtml())"
            color="primary"
            class="text-capitalize ml-0 mr-4"
          >
            <span class="ml-1 mr-3">Interactive Summary</span>
            <v-icon>swipe</v-icon>
          </v-btn>
          <v-btn
            v-if="getSummaryPdf()"
            color="primary"
            v-on:click="openInNewTab(getSummaryPdf())"
            class="text-capitalize ml-0 mr-4"
          >
            <span class="ml-1 mr-3">Summary PDF</span>
            <v-icon>summarize</v-icon>
          </v-btn>
          <v-btn
            v-if="getFullZipFile()"
            color="primary"
            class="text-capitalize ml-0"
            v-on:click="openInNewTab(getFullZipFile())"
          >
            <span class="ml-1 mr-3">Download All As ZIP</span>
            <v-icon>install_desktop</v-icon>
          </v-btn>
        </div>
        <div class="mb-4">
          View Results
        </div>
        <div class="mb-4">
          <ResultsTable
            :free="free"
            :job="job"
            :sections="allSectionsAndFiles"
          />
        </div>
        <JobResultsShare :free="free" :results="results" />
        <div class="mb-2">
          Help
        </div>
        <div class="mb-4">
          We have a guide to help interpret your results
          <a href="https://docs.bugseq.com/output/overview/" target="_blank"
            >here</a
          >. If you have questions about your results, please don't hesitate to
          reach out to
          <a href="mailto:support@bugseq.com">support@bugseq.com</a>.
        </div>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { components } from "@bugseq-site/app/src/lib/api/api";
import { dispatchGetFileLink, dispatchGetResults, dispatchGetLabMembership } from "@bugseq-site/app/src/store/modules/api/actions";
import { readJobResults, readLabs } from "@bugseq-site/app/src/store/modules/api/getters";
import JobResultsChip from "@bugseq-site/shared/src/components/JobResultsChip.vue";
import ResultsTable, { PerItemActionType, Section } from "@bugseq-site/app/src/components/app/ResultsTable.vue";
import JobResultsShare from "@bugseq-site/app/src/components/app/JobResultsShare.vue";
import { formatTime } from "@bugseq-site/shared/src/lib/utils";

type BackendJobRunFile = components['schemas']['JobRunFile']
interface JobRunFile extends BackendJobRunFile {
  helptext?: string
}

const ComponentProps = Vue.extend({
  props: {
    free: {
      type: Boolean,
      default: false,
    },
    job: String,
  },
});

const allResultsSection = "All Results";
const summarySection = "Summary Report";

const summaryHtmlFilter = (filename: string): boolean =>
  filename.startsWith("summary_report") && filename.endsWith(".html"); // covers summary_report- (old) and summary_reports/
const summaryPdfFilter = (filename: string): boolean =>
  filename.startsWith("summary_report") && filename.endsWith(".pdf"); // covers summary_report- (old) and summary_reports/
const fullZipFilter = (filename: string): boolean =>
  filename.startsWith("bugseq_results-") && filename.endsWith(".zip");

const outputSort = (o1: components["schemas"]["JobRunFile"], o2: components["schemas"]["JobRunFile"]) => {
  // first sort html to the top
  if (o1.filename.endsWith(".html") !== o2.filename.endsWith(".html")) {
    return o1.filename.endsWith(".html") ? -1 : 1;
  }

  // then just sort by filename
  if (o1.filename < o2.filename) {
    return -1;
  }
  if (o1.filename > o2.filename) {
    return 1;
  }

  // names must be equal
  return 0;
};

@Component({
  filters: {
    formatTime,
  },
  components: {
    ResultsTable,
    JobResultsChip,
    JobResultsShare,
  },
})
export default class JobResults extends ComponentProps {
  private inputFilesTruncated: boolean = true;
  private inputFilesTruncationThreshold: number = 5;

  public async mounted() {
    dispatchGetResults(this.$store, {
      jobId: this.job,
      free: this.free,
    });
    if (!this.free) {
      dispatchGetLabMembership(this.$store)
    }
  }

  get results() {
    const results = readJobResults(this.$store);
    if (!results[this.job]) {
      return null;
    }
    return results[this.job];
  }

  public loading() {
    if (!this.results) {
      return true;
    }

    return false;
  }

  public incomplete() {
    if (!this.results) {
      return false;
    }

    if (
      this.results.job_status !== "success" &&
      this.results.job_status !== "failure" &&
      this.results.job_status !== "marked_in_error"
    ) {
      return true;
    }

    return false;
  }

  public noResultsProduced() {
    if (!this.results) {
      return false;
    }

    if (this.results.outputs && this.results.outputs.length > 0) {
      return false;
    }

    return true;
  }

  public shouldTruncateInputFiles() {
    if (!this.results) {
      return false;
    }

    return (
      this.inputFilesTruncated &&
      this.results.inputs.length > this.inputFilesTruncationThreshold
    );
  }

  public getInputFiles() {
    if (!this.results) {
      return;
    }

    // need to truncate long lists
    let files = this.results.inputs;
    if (this.shouldTruncateInputFiles()) {
      files = files.slice(0, this.inputFilesTruncationThreshold);
    }

    let str = files.map((i) => i.filename).join(", ");
    if (this.shouldTruncateInputFiles()) {
      str += "...";
    }
    return str;
  }

  public getOwnerName() {
    if (this.results === null || this.results.owner_id === null) {
      return "Unknown"
    }

    const owner = ((readLabs(this.$store) || []) as components["schemas"]["ListMembershipsResponse"][])
      .flatMap((l) => l.members)
      .filter((m) => m.id === this.results!.owner_id);
    if (owner.length > 0) {
      return owner[0].first_name + " " + owner[0].last_name;
    }

    return "Unknown";
  }

  public getLab(): components['schemas']['ListMembershipsResponse'] | null {
    if (this.results === null || this.results.org_id === null) {
      return null
    }

    const lab = ((readLabs(this.$store) || []) as components["schemas"]["ListMembershipsResponse"][])
      .find((l) => l.id === this.results!.org_id)

    if (!lab) {
      return null
    }

    return lab
  }

  public getLabName(): string | null {
    const lab = this.getLab()
    if (!lab) {
      return null
    }
    return lab.name
  }

  public getLabId(): string | null {
    const lab = this.getLab()
    if (!lab) {
      return null
    }
    return lab.id
  }

  public get allSectionsAndFiles() {
    if (!this.results || !this.results.outputs) {
      return [];
    }

    const sections: Section[] = [
      {
        name: allResultsSection,
        filterFunc: (f) => fullZipFilter(f.filename),
        subsections: [],
        outputs: [],
        hidden: true,
      },
      {
        name: summarySection,
        description:
          "A summary report of all samples submitted for analysis, including quality control, sample composition and more.",
        // The multiqc.html is to retain compatibility with user jobs before Sep 21, 2021
        // quality_control- is to retain compatibility with user jobs before Oct 12, 2021
        filterFunc: (f) =>
          (f.filename.startsWith("quality_control-") &&
            f.filename.endsWith(".html")) || // legacy
          f.filename === "multiqc.html" || // legacy
          summaryHtmlFilter(f.filename) ||
          summaryPdfFilter(f.filename),
        subsections: [],
        outputs: [],
        hidden: false,
      },
      {
        name: "Per-Sample Reports",
        description: "Detailed data on each sample submitted to BugSeq.",
        filterFunc: (f) => f.filename.startsWith("sample_reports/"),
        subsections: [],
        outputs: [],
        hidden: false,
      },
      {
        name: "Metagenomic Classification",
        description:
          "Summary files containing the taxonomic composition of submitted samples. HTML files may be opened in a web browser.",
        filterFunc: (f) =>
          f.filename.startsWith(
            "metagenomic_classification/metagenomic_classification-",
          ),
        subsections: [
          {
            name: "Read-Based Classification",
            description:
              "Metagenomic classification using the algorithm described by Fan, Huang & Chorlton (2021) in BMC Bioinformatics.",
            filterFunc: (f) =>
              f.filename.startsWith("metagenomic_classification/read-based/") ||
              f.filename.startsWith("metagenomic_classification-"), // legacy
            subsections: [
              {
                name: "Details",
                filterFunc: (f) =>
                  f.filename.startsWith(
                    "metagenomic_classification/read-based/details/",
                  ) ||
                  (f.filename.startsWith("metagenomic_classification/") &&
                    f.filename.endsWith(".csv")), // legacy
                subsections: [],
                outputs: [],
                hidden: false,
              },
            ],
            outputs: [],
            hidden: false,
          },
          {
            name: "Assembly-Based Classification",
            description:
              "Metagenomic classification using the algorithm described by Chandrakumar et al. (2022) in Communications Biology.",
            filterFunc: (f) =>
              f.filename.startsWith(
                "metagenomic_classification/assembly-based/",
              ) ||
              (f.filename.startsWith("assembly/") &&
                f.filename.endsWith(".kreport")) || // legacy
              f.filename.startsWith("assembly/metagenomic_classification-"), // legacy
            subsections: [],
            outputs: [],
            hidden: false,
          },
          {
            name: "Amplicon Classification",
            description:
              "Metagenomic classification using algorithms designed for amplicon sequencing (eg. 16S). Nanopore classification is described by Jung & Chorlton (2021) in BioRxiv.",
            filterFunc: (f) =>
              f.filename.startsWith("16S/") || // legacy
              f.filename.startsWith("metagenomic_classification/amplicon/"),
            subsections: [],
            outputs: [],
            hidden: false,
          },
          {
            name: "Kraken-Formatted Reports",
            filterFunc: (f) =>
              f.filename.startsWith("metagenomic_classification/kreports/"),
            subsections: [],
            outputs: [],
            hidden: false,
          },
        ],
        outputs: [],
        hidden: false,
      },
      {
        name: "Outbreak Analysis",
        description:
          "Outbreak analysis demonstrates the genetic relationship between microbial genomes contained in this analysis. Genomes from all previous analyses are included for users who are part of a lab.",
        filterFunc: (f) => f.filename.startsWith("outbreak_analysis"),
        subsections: [],
        outputs: [],
        hidden: false,
      },
      {
        name: "Assembly",
        description:
          "High quality metagenomic assemblies for downstream processing. No further processing is needed to use these.",
        filterFunc: (f) =>
          f.filename.endsWith(".fasta") ||
          f.filename.endsWith(".fasta.gz") ||
          f.filename.endsWith(".fna"),
        subsections: [
          {
            name: "Metagenomic Bins",
            filterFunc: (f) =>
              f.filename.startsWith("assembly/") &&
              f.filename.includes("/bins/"),
            subsections: [],
            outputs: [],
            hidden: false,
            perItemActions: [{type: PerItemActionType.BlastAssembly}],
          },
          {
            name: "Contig Classifications",
            filterFunc: (f) =>
              f.filename.startsWith("assembly/") &&
              f.filename.endsWith("contig_classification.txt"),
            subsections: [],
            outputs: [],
            hidden: false,
          },
        ],
        outputs: [],
        hidden: false,
      },
      {
        name: "Demultiplexed FASTQ Files",
        description:
          "Input files that have undergone demultiplexing, adapter trimming and quality filtering.",
        filterFunc: (f) =>
          f.filename.endsWith(".fastq") || f.filename.endsWith(".fastq.gz"),
        subsections: [],
        outputs: [],
        hidden: false,
      },
      {
        name: "Hidden",
        filterFunc: (f) => false, // we can use this filter to hide arbitrary outputs as we need
        subsections: [],
        outputs: [],
        hidden: true,
      },
      {
        name: "Other",
        filterFunc: (f) => true,
        subsections: [],
        outputs: [],
        hidden: false,
      },
    ];

    this.results.outputs.forEach((o) => {
      this.annotateFile(o);
      this.bucketFile(o, sections);
    });

    this.sortBuckets(sections);
    return sections;
  }

  public annotateFile(file: JobRunFile) {
    // TODO: fill in array with {filterFunc, helptext}, e.g.
    // {filterFunc: (f) => f.filename.startsWith("foo"), helptext: "This file is so cool"}
    const helptexts: Array<{
      filterFunc: (f: JobRunFile) => boolean;
      helptext: string
    }> = [];

    for (const ht of helptexts) {
      if (ht.filterFunc(file)) {
        file.helptext = ht.helptext;
        return;
      }
    }
  }

  public bucketFile(file: components["schemas"]["JobRunFile"], buckets: Section[]): boolean {
    for (const bucket of buckets) {
      // first traverse subs
      if (this.bucketFile(file, bucket.subsections)) {
        return true;
      }

      if (bucket.filterFunc(file)) {
        bucket.outputs.push(file);
        return true;
      }
    }

    return false;
  }

  public sortBuckets(buckets: Section[]): Section[] {
    for (const bucket of buckets) {
      // first traverse subs
      this.sortBuckets(bucket.subsections);

      bucket.outputs.sort(outputSort);
    }

    return buckets;
  }

  public getSummaryHtml() {
    const binned = this.allSectionsAndFiles;
    const section = binned.find((b) => b.name === summarySection);
    if (!section) {
      return;
    }

    const summaryHtml = section.outputs.find((o) =>
      summaryHtmlFilter(o.filename),
    );
    if (!summaryHtml) {
      return;
    }

    return summaryHtml.filename;
  }

  public getSummaryPdf() {
    const binned = this.allSectionsAndFiles;
    const section = binned.find((b) => b.name === summarySection);
    if (!section) {
      return;
    }

    const summaryPdf = section.outputs.find((o) =>
      summaryPdfFilter(o.filename),
    );
    if (!summaryPdf) {
      return;
    }

    return summaryPdf.filename;
  }

  public getFullZipFile() {
    const binned = this.allSectionsAndFiles;
    const section = binned.find((b) => b.name === allResultsSection);
    if (!section) {
      return;
    }

    const fullZip = section.outputs.find((o) => fullZipFilter(o.filename));
    if (!fullZip) {
      return;
    }

    return fullZip.filename;
  }

  public async openInNewTab(filename) {
    const fileLink = await dispatchGetFileLink(this.$store, {
      jobId: this.job,
      filename,
      free: this.free,
    });
    const newWindow = window.open(fileLink, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  }
}
</script>

<style scoped>
.full-size-font {
  font-size: 18px !important;
}

.input-files {
  white-space: normal;
}
</style>

<style>
.input-files-list-tile .v-list__tile {
  min-height: 72px;
  height: auto;
}
</style>
